<template>
	<div>
		<b-alert variant="secondary" show>
			<div class="alert-body d-flex align-items-center py-1">
				<div class="mr-1">
					<feather-icon icon="InfoIcon" size="24" class="mr-1" />
					<span>Make sure the data you are looking for have been migrated!</span>
				</div>
			</div>
		</b-alert>
		<b-tabs vertical content-class="col-12 col-md-10 mt-1 mt-md-0" pills nav-wrapper-class="col-md-2 col-12" nav-class="nav-left" lazy>
			<b-tab title="Data">
				<Data :workers="workers" />
			</b-tab>
			<b-tab title="Summary">
				<Summary :workers="workers" />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import store from '@/store';
import { BTabs, BAlert, BTab } from 'bootstrap-vue';
import Data from './Data.vue';
import Summary from './Summary.vue';

export default {
	name: 'WorkerIndex',

	components: { BTabs, BAlert, BTab, Data, Summary },

	data: () => ({
		workers: [],
	}),

	created() {
		this.fetchWorkers();
	},

	methods: {
		async fetchWorkers() {
			try {
				this.workers = await store.dispatch('workers/getWorkers', this.$route.params.id);
			} catch (error) {
				console.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
