<template>
	<b-tabs pills content-class="pt-2" lazy>
		<b-tab title="Informations">
			<worker-info :worker="worker" />
		</b-tab>
		<b-tab title="Beneficiary reports" @click="fetchDailyReports">
			<base-table
				v-if="!dailyLoading"
				:headers="beneficiaryHeaders"
				:items="beneficiaryReports"
				table-top
				paginate
				class="shadow-none border-secondary"
			>
				<template #cell(images)="{ item }">
					<table-images :images="item.images"></table-images>
				</template>
				<template #cell(map)="row">
					<table-map :loc="row.item.map" :project="projectLoc"></table-map>
				</template>
			</base-table>
			<div v-else class="">
				<div class="text-center text-primary mt-3 mb-2">
					<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
					<span class="d-block mt-1">Loading...</span>
				</div>
			</div>
		</b-tab>
		<b-tab title="Paying reports" @click="fetchPayingReports">
			<base-table
				v-if="!payingLoading"
				:headers="payingHeaders"
				:items="payingReports"
				table-top
				paginate
				class="shadow-none border-secondary"
			>
				<template #cell(images)="{ item }">
					<table-images :images="item.images"></table-images>
				</template>
				<template #cell(map)="row">
					<table-map :loc="row.item.map" :project="projectLoc"></table-map>
				</template>
				<template #cell(paid_amount)="{ value }">
					<strong>{{ formatMoney(value) }}</strong>
				</template>
			</base-table>
			<div v-else class="">
				<div class="text-center text-primary mt-3 mb-2">
					<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
					<span class="d-block mt-1">Loading...</span>
				</div>
			</div>
		</b-tab>
	</b-tabs>
</template>

<script>
import { BSpinner, BTabs, BTab } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import TableImages from '@/components/TableImages.vue';
import WorkerInfo from '@/components/WorkerInfo.vue';
import TableMap from '@/components/TableMap.vue';
import { getProjectLocation, formatMoney } from '@/utils/helpers';
import store from '@/store';

export default {
	name: 'WorkerDetails',

	components: { BSpinner, BTabs, BTab, BaseTable, TableImages, TableMap, WorkerInfo },

	props: {
		worker: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		title: '',
		dailyLoading: false,
		payingLoading: false,

		beneficiaryReports: [],
		payingReports: [],

		beneficiaryHeaders: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'day_date', sortable: true },
			{ key: 'images', sortable: false },
			{ key: 'map', sortable: false }
		],

		payingHeaders: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'paid_amount', sortable: true },
			{ key: 'day_date', sortable: true },
			{ key: 'images', sortable: false },
			{ key: 'map', sortable: false }
		]
	}),

	computed: {
		projectLoc() {
			return getProjectLocation();
		}
	},

	methods: {
		getGender(gender) {
			return gender === 1 ? 'ذكر' : 'انثى';
		},

		async fetchDailyReports() {
			this.dailyLoading = true;
			try {
				this.beneficiaryReports = await store.dispatch('workers/getDailyReports', {
					projectId: this.$route.params.id,
					id: this.worker.id
				});

				this.dailyLoading = false;
			} catch (error) {
				console.log(error);
				this.dailyLoading = false;
			}
		},

		async fetchPayingReports() {
			this.payingLoading = true;
			try {
				this.payingReports = await store.dispatch('workers/getPayingReports', {
					projectId: this.$route.params.id,
					id: this.worker.id
				});

				this.payingLoading = false;
			} catch (error) {
				console.log(error);
				this.payingLoading = false;
			}
		},

		formatMoney
	}
};
</script>

<style lang="scss" scoped></style>
