<template>
	<div>
		<base-table
			:headers="headers"
			:items="workers"
			paginate
			table-top
			has-select
			excel-file-name="project-workers"
			:server-excel-export-url="`/beneficiaries/${$route.params.id}/export-excel`"
			@selectChange="selectWorkers"
		>
			<template #action>
				<b-button variant="primary" :disabled="!selected.length" @click="makeCards">
					Make Cards
				</b-button>
			</template>
			<template #cell(actions)="data">
				<div class="text-nowrap">
					<b-button variant="outline-secondary" class="cursor-pointer" size="sm" @click="showWorkerInfo(data.item)">
						<span class="align-middle">View </span>
					</b-button>
				</div>
			</template>
			<template #cell(identity_card)="{ item }">
				<table-images :images="item.identity_card"></table-images>
			</template>
		</base-table>

		<b-modal v-model="workerInfo" :title="modalTitle" size="lg" hide-footer centered>
			<Details :worker="currentWorker" />
		</b-modal>
	</div>
</template>

<script>
import { BButton, BModal } from 'bootstrap-vue';
import TableImages from '@/components/TableImages.vue';
import BaseTable from '@/components/BaseTable.vue';
import { saveState } from '@/utils/helpers';
import chartData from '../../../m&e/chartjsData';
import Details from './Details.vue';

export default {
	name: 'WorkerData',

	components: {
		BButton,
		BModal,
		Details,
		BaseTable,
		TableImages,
	},

	props: {
		workers: {
			type: Array,
			default: () => [],
		},
	},

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'full_name', sortable: true },
			{ key: 'phone', sortable: true },
			{ key: 'identity_type', sortable: true },
			{ key: 'identity_number', sortable: true },
			{ key: 'identity_card', sortable: true },
			{ key: 'gender' },
			{ key: 'actions' },
		],

		selected: [],

		chartData,

		workerInfo: false,
		currentWorker: undefined,
	}),

	computed: {
		modalTitle() {
			return this.currentWorker?.full_name;
		},
	},

	methods: {
		showWorkerInfo(worker) {
			this.currentWorker = worker;
			this.workerInfo = true;
		},

		selectWorkers(data) {
			this.selected = data.map((id) => ({
				...this.workers.find((w) => w.id === id),
			}));
		},

		makeCards() {
			saveState('workers-cards-info', this.selected);
			this.$router.push({ name: 'workers.cards' });
		},
	},
};
</script>

<style lang="scss" scoped></style>
