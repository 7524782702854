<template>
	<div>
		<b-card no-body>
			<b-card-header>
				<b-button variant="primary" @click="exportExcel">Export Excel</b-button>
			</b-card-header>
			<div dir="rtl" align="left" class="position-relative table-responsive pb-2">
				<table id="workersSummaryTable" class=" table b-table table-bordered">
					<tbody>
						<tr>
							<th>بيان العمالة</th>
							<th>النوع</th>
							<th>نازح</th>
							<th>عائد</th>
							<th>مقيم</th>
							<th>من خلرج المنطقة</th>
							<th>اجمالي النوع</th>
							<th>اجمالي</th>
						</tr>
						<tr>
							<td rowspan="2">اجمالي العمالة الماهرة</td>
							<td>ذكور</td>
							<td>{{ countByLiving(skilledMale, 'Displaced') }}</td>
							<td>{{ countByLiving(skilledMale, 'Returned') }}</td>
							<td>{{ countByLiving(skilledMale, 'Resident') }}</td>
							<td>{{ countByLiving(skilledMale, 'Outside Area') }}</td>
							<td>{{ skilledMale.length }}</td>
							<td rowspan="2">{{ skilled.length }}</td>
						</tr>
						<tr>
							<td>اناث</td>
							<td>{{ countByLiving(skilledFemale, 'Displaced') }}</td>
							<td>{{ countByLiving(skilledFemale, 'Returned') }}</td>
							<td>{{ countByLiving(skilledFemale, 'Resident') }}</td>
							<td>{{ countByLiving(skilledFemale, 'Outside Area') }}</td>
							<td>{{ skilledFemale.length }}</td>
						</tr>
						<tr>
							<td rowspan="2">اجمالي العمالة غير الماهرة</td>
							<td>ذكور</td>
							<td>{{ countByLiving(notSkilledMale, 'Displaced') }}</td>
							<td>{{ countByLiving(notSkilledMale, 'Returned') }}</td>
							<td>{{ countByLiving(notSkilledMale, 'Resident') }}</td>
							<td>{{ countByLiving(notSkilledMale, 'Outside Area') }}</td>
							<td>{{ notSkilledMale.length }}</td>
							<td rowspan="2">{{ notSkilled.length }}</td>
						</tr>
						<tr>
							<td>اناث</td>
							<td>{{ countByLiving(notSkilledFemale, 'Displaced') }}</td>
							<td>{{ countByLiving(notSkilledFemale, 'Returned') }}</td>
							<td>{{ countByLiving(notSkilledFemale, 'Resident') }}</td>
							<td>{{ countByLiving(notSkilledFemale, 'Outside Area') }}</td>
							<td>{{ notSkilledFemale.length }}</td>
						</tr>
						<tr>
							<td>اجمالي</td>
							<td>----</td>
							<td>
								{{
									countByLiving(skilledMale, 'Displaced') +
										countByLiving(skilledFemale, 'Displaced') +
										countByLiving(notSkilledMale, 'Displaced') +
										countByLiving(notSkilledFemale, 'Displaced')
								}}
							</td>
							<td>
								{{
									countByLiving(skilledMale, 'Returned') +
										countByLiving(skilledFemale, 'Returned') +
										countByLiving(notSkilledMale, 'Returned') +
										countByLiving(notSkilledFemale, 'Returned')
								}}
							</td>
							<td>
								{{
									countByLiving(skilledMale, 'Resident') +
										countByLiving(skilledFemale, 'Resident') +
										countByLiving(notSkilledMale, 'Resident') +
										countByLiving(notSkilledFemale, 'Resident')
								}}
							</td>
							<td>
								{{
									countByLiving(skilledMale, 'Outside Area') +
										countByLiving(skilledFemale, 'Outside Area') +
										countByLiving(notSkilledMale, 'Outside Area') +
										countByLiving(notSkilledFemale, 'Outside Area')
								}}
							</td>
							<td>{{ skilled.length + notSkilled.length }}</td>
							<td>{{ skilled.length + notSkilled.length }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</b-card>
	</div>
</template>

<script>
import { BCard, BCardHeader, BButton } from 'bootstrap-vue';
import * as xlsx from 'xlsx';

export default {
	name: 'WorkersSummary',

	components: { BCard, BCardHeader, BButton },

	props: {
		workers: {
			type: Array,
			default: () => [],
		},
	},

	computed: {
		skilled() {
			return this.workers.filter((w) => w.is_skilled);
		},

		notSkilled() {
			return this.workers.filter((w) => !w.is_skilled);
		},

		male() {
			return this.workers.filter((w) => w.gender_id === 1);
		},

		skilledMale() {
			return this.workers.filter((w) => w.is_skilled && w.gender_id === 1);
		},

		notSkilledMale() {
			return this.workers.filter((w) => !w.is_skilled && w.gender_id === 1);
		},

		skilledFemale() {
			return this.workers.filter((w) => w.is_skilled && w.gender_id === 2);
		},

		notSkilledFemale() {
			return this.workers.filter((w) => !w.is_skilled && w.gender_id === 2);
		},

		female() {
			return this.workers.filter((w) => w.gender_id === 2);
		},
	},

	methods: {
		countByLiving(list, condition) {
			return list.filter((w) => w.living_conditions === condition).length;
		},

		exportExcel() {
			const el = document.getElementById('workersSummaryTable');
			const sheet = xlsx.utils.table_to_book(el, {
				sheet: 'Sheet JS',
			});

			xlsx.writeFile(sheet, 'workers-summary.xlsx');
		},
	},
};
</script>

<style lang="scss" scoped>
tr > th {
	background-color: #f3f2f7;
	border-bottom: 2px solid #ebe9f1;
}
</style>
